import { createSlice } from '@reduxjs/toolkit'

export const coinSlice = createSlice({
    name: 'coin',
    initialState: {
        coins: [],
        tradePairs: [],
        selectedCryptoPair: null, // Added for storing the selected crypto pair
        selectedFiatPair: null,   // Added for storing the selected fiat pair
    },
    reducers: {
        setCoinData: (state, action) => {
            state.coins = action.payload
        },
        setTradePairs: (state, action) => {
            state.tradePairs = action.payload
        },
        setSelectedCryptoPair: (state, action) => {
            state.selectedCryptoPair = action.payload
        },
        setSelectedFiatPair: (state, action) => {
            state.selectedFiatPair = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setCoinData, setTradePairs, setSelectedCryptoPair, setSelectedFiatPair } = coinSlice.actions

export default coinSlice.reducer