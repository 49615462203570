import { useState, useEffect } from 'react';
import { getAllCoins } from '../utils/apiUtils';

const useFetchAllCoins = () => {
    const [coins, setCoins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCoins = async () => {
        try {
            const data = await getAllCoins();
            setCoins(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCoins();
    }, []);

    return { coins, loading, error, refreshCoins: fetchCoins };
};

export default useFetchAllCoins;