import { useState, useEffect } from 'react';
import axios from 'axios';

const useTradePairs = () => {
    const [tradePairs, setTradePairs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTradePairs = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tradePairs`, { withCredentials: true });
            if (response.data) {
                setTradePairs(response.data);
            }
        } catch (err) {
            console.error('Error fetching tradePairs:', err);
            setError('Failed to load trade pairs. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTradePairs();
    }, []);

    return { tradePairs, loading, error, refreshTradePairs: fetchTradePairs };
};

export default useTradePairs;
