import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatCrypto, formatNumber } from '../../utils/helper';
import AmountPlaceholder from '../Placeholders/AmountPlaceholder';
import useFetchWalletBalance from '../../hooks/useFetchWalletBalance';
import axios from 'axios';
import { useTextClass } from '../../hooks/useTextClass';

export default function TradeQuoteSwapModal({ show, onHide, coin1, coin2, coin1Amount, coin2Amount, gasFees, rate }) {
    const { textClass, getModeClass } = useTextClass();
    const isLogged = useSelector((state) => state.user?.isLogged);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);

    // Use useEffect to fetch wallet balance only if the user is logged in
    const { walletBalance: fetchedWalletBalance } = useFetchWalletBalance(coin1?.testnetShortCode);

    useEffect(() => {
        if (isLogged && fetchedWalletBalance !== null) {
            setWalletBalance(fetchedWalletBalance);
        } else {
            setWalletBalance(null);
        }
    }, [isLogged, fetchedWalletBalance]);

    const handleTrade = async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/transaction/swap`, {
                coinForm: coin1.testnetShortCode,
                fromAmount: coin1Amount,
                coinTo: coin2.testnetShortCode,
                toAmount: coin2Amount
            }, { withCredentials: true });
            if (response.data) {
                setSuccessMessage("Your exchange request submitted. It will take some time depending on the blockchain.");
                setTimeout(() => {
                    setSuccessMessage(null);
                    onHide();
                }, 3000);
            }
        } catch (error) {
            console.error('Error buying:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered className={getModeClass}>
            <Modal.Header closeButton>
                <Modal.Title className={textClass}>Exchange Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {successMessage && (
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                )}
                {isLoading && (
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col">
                        <h3 className={textClass}>Exchange {coin1Amount} {coin1?.shortCode} for {coin2Amount} {coin2?.shortCode}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>{coin1?.shortCode} Rate:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatCrypto(parseFloat(rate))} {coin2?.shortCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>Exchange Amount:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{coin1Amount}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>Network Fees (Included):</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatCrypto(parseFloat(gasFees))}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>You will receive:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatCrypto(parseFloat(coin2Amount))} {coin2?.shortCode}</p>
                    </div>
                </div>
                {isLogged && (
                    <p className={textClass}>
                        <strong>Available Funds:</strong>{' '}
                        {walletBalance !== null ? (
                            walletBalance >= coin2Amount ? (
                                `${formatNumber(walletBalance)} ${coin1?.shortCode}`
                            ) : (
                                <span className="text-danger">
                                    {formatNumber(walletBalance)} {coin1?.shortCode} (Not enough funds to exchange)
                                </span>
                            )
                        ) : (
                            <AmountPlaceholder />
                        )}
                    </p>
                )}
                {!isLogged && (
                    <p className={textClass}>
                        This quote is indicative only. Please <Link className='text-info fw-medium text-decoration-underline' to="/signin">Sign in</Link> or <Link className='text-info fw-medium text-decoration-underline' to="/signup">Sign up</Link> to proceed with the trade.
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!isLogged ? (
                    <Button as={Link} to="/signup" variant="primary">
                        Get Started
                    </Button>
                ) : (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Link to="/client/buy-crypto" className="btn btn-warning">
                            Buy with Fiat
                        </Link>
                        <Button variant="primary" disabled={walletBalance < coin1Amount} onClick={handleTrade}>
                            Confirm Trade
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}
