import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './components/Layouts/Header';
import Footer from './components/Layouts/Footer';
import GoToTop from './components/utils/GoToTop';
import { useSelector } from 'react-redux';

// Public Layout
const Public = () => (
    <>
        <Header />
        <main>
            <Outlet />
        </main>
        <Footer />
        <GoToTop />
    </>
);

// Client Layout
const Client = () => {
    const isLogged = useSelector((state) => state.user?.isLogged);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogged) {
            navigate('/signin');
        }
    }, [isLogged, navigate]);

    if (!isLogged) return null; // Avoid rendering until redirected

    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
            <GoToTop />
        </>
    );
};

// Admin Layout
const Admin = () => {
    const userRole = useSelector((state) => state.user.userDetails?.role);
    const navigate = useNavigate();

    useEffect(() => {
        if (userRole !== 'admin') {
            navigate('/signin');
        }
    }, [userRole, navigate]);

    if (userRole !== 'admin') return null; // Avoid rendering until redirected

    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
            <GoToTop />
        </>
    );
};

export default { Public, Client, Admin };