import { createClient } from 'contentful';

export const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const formatNumber = (number) => {
    if (!number) return '0';
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
export const formatCrypto = (number) => {
    if (!number) return '0.000000'; // Default to 6 decimal places if number is falsy
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
    });
};

