import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TradeBuySell from '../Trades/TradeBuySell';
import { useTextClass } from '../../hooks/useTextClass';

export default function Banner() {
    const bgImage = '/assets/images/home/bg.png';
    const bgDarkImage = '/assets/images/home/bg-dark.png';
    const { mode, textClass } = useTextClass();

    return (
        <section className="banner banner--style1" style={{ backgroundImage: `url(${mode === 'dark' ? bgDarkImage : bgImage})` }}>
            <div className="container">
                <div className="banner__wrapper pt-5">
                    <div className="row py-5 d-flex align-items-center">
                        <div className="col-lg-6 col-md-7">
                            <div className="banner__content aos-animate" data-aos="fade-right" data-aos-duration="800">
                                <h1 className={`banner__content-heading ${textClass}`}>
                                    Welcome to <span>Indexa</span> Cryptocurrency
                                </h1>
                                <p className={`banner__content-moto ${textClass}`}>
                                    We are a premium cryptocurrency exchange tailored for <span>Institutions</span> but <br />preferred by everyday <span>Traders.</span>
                                </p>
                                <div className="banner__btn-group btn-group">
                                    <Link to="/contact-us" className={`trk-btn trk-btn--outline22 ${textClass}`}> Contact us</Link>
                                    <Link to="/signup" className="trk-btn trk-btn--primary trk-btn--arrow">Register <FaArrowRight /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-6">
                            <div className="d-flex justify-content-center justify-content-lg-en aos-animate" data-aos="fade-left" data-aos-duration="800">
                                <div className="trade-options">
                                    <div className="card rounded-xl w-100">
                                        <div className="card-body">
                                            <TradeBuySell />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner__shape">
            </div>
        </section >
    );
}
