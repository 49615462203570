import React, { useEffect, Suspense } from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './routes';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function App() {

  const mode = useSelector((state) => state.user.mode);

  useEffect(() => {
    document.body.style.backgroundColor = mode === 'dark' ? '#001a41' : '#ffffff';
  }, [mode]);

  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<Spinner animation="border" variant="dark" />}>
          <AppRoutes />
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;