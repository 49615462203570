import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTextClass } from '../../hooks/useTextClass';

export default function Footer() {
    const currentYear = moment().format('YYYY');
    const { mode } = useTextClass();

    return (
        <footer className="footer brand-1">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__top footer__top--style1">
                        <div className="row gy-5 gx-4">
                            <div className="col-12 col-md-3">
                                <div className="footer__about">
                                    <Link to="/" className="footer__about-logo">
                                        {mode === 'dark' ?
                                            <img className="dark logo" src="/assets/images/logo-dark.png" alt="logo" />
                                            :
                                            <img className="dark logo" src="/assets/images/logo-light.png" alt="logo" />
                                        }
                                    </Link>
                                    <p className="footer__about-moto">
                                        Indexa South Africa (Pty) Limited is a licensed financial services provider (FSP #53427)
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="row">
                                    <div className="col-6 col-md-2">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Company</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link to='/about-us'>About Us</Link></li>
                                                    <li className="footer__linklist-item"><Link to='/blogs'>Blog</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/careers">Careers</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Services</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link to="/buy-and-sell">Buy & Sell</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/otc">OTC Desk</Link></li>
                                                    {/* <li className="footer__linklist-item"><Link to="/institutions">Institutions</Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Support</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    {/* <li className="footer__linklist-item"><Link to="/">FAQs</Link></li> */}
                                                    <li className="footer__linklist-item"><Link to="/signup">Create an Account</Link></li>
                                                    <li className="footer__linklist-item"><a href="mailto:Support@xenely.com" target='_blank' rel='noreferrer'>Customer Support</a></li>
                                                    <li className="footer__linklist-item"><a href="https://t.me/xeno_otc" target="_blank" rel="noopener noreferrer">Telegram</a></li>
                                                    {/* <li className="footer__linklist-item"><Link to="/">Fees </Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>International Expansion</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"> <Link to="/ae">United Arab Emirates</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/eu">Europe</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/">West Africa</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-6 col-md-4">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Legal</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link to="/terms-of-service">Terms and Conditions</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/disclosure-statement">Disclosure Statement</Link></li>
                                                    <li className="footer__linklist-item"><Link to='/paia-manual'>PAIA Manual</Link></li>
                                                    <li className="footer__linklist-item"><Link to='/paia-statement'>PAIA Statement</Link></li>
                                                    <li className="footer__linklist-item"><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__end">
                            <div className="footer__end-copyright">
                                <p className="mb-0">Copyright © {currentYear} Indexa South Africa (Pty) Limited - All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__shape">
                <span className="footer__shape-item footer__shape-item--1"><img src="/assets/images/footer/1.png" alt="shape icon" /></span>
                <span className="footer__shape-item footer__shape-item--2"><span></span></span>
            </div>
        </footer>
    );
}
